<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <!-- 性別 -->
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>性別</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="genderFilter"
            :options="genderOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:genderFilter', val)"
          />
        </b-col>
        <!-- Line 綁定 -->
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>Line 綁定</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="isLineLinkedFilter"
            :options="isLineLinkedOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:isLineLinkedFilter', val)"
          />
        </b-col>
        <!-- LINE ID -->
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>Line ID</label>
          <b-form-input
            :value="lineIdFilter"
            class="d-inline-block mr-1"
            @input="(val) => $emit('update:lineIdFilter', val)"
          />
        </b-col>
        <!-- 電話 -->
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>電話</label>
          <b-form-input
            :value="phoneFilter"
            class="d-inline-block mr-1"
            @input="(val) => $emit('update:phoneFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BFormInput,
  BRow,
  BCol,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    vSelect,
  },
  props: {
    genderFilter: {
      type: [String, null],
      default: null,
    },
    isLineLinkedFilter: {
      type: [String, null],
      default: null,
    },
    lineIdFilter: {
      type: [String, null],
      default: null,
    },
    phoneFilter: {
      type: [String, null],
      default: null,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
    isLineLinkedOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
