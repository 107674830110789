import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "user", label: "使用者" },
    { key: "email", label: "Email" },
    { key: "address", label: "地址" },
    { key: "birth", label: "生日", sortable: true },
    { key: "gender", label: "性別", sortable: false },
    { key: "isLinked", label: "綁定", sortable: true },
    { key: "joinTime", label: "加入時間", sortable: true },
    { key: "phone", label: "電話" },
    { key: "tags", label: "標籤" },
    { key: "actions", label: "" },
  ];
  const perPage = ref(20);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [20, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("");
  const isSortDirDesc = ref(true);
  const genderFilter = ref(null);
  const isLineLinkedFilter = ref(null);
  const lineIdFilter = ref("");
  const phoneFilter = ref("");

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      genderFilter,
      isLineLinkedFilter,
      lineIdFilter,
      phoneFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch("app-user/fetchUsers", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        gender: genderFilter.value,
        isLineLinked: isLineLinkedFilter.value,
        lineId: lineIdFilter.value,
        phone: phoneFilter.value,
      })
      .then((response) => {
        const { contacts, total } = response.data;

        callback(contacts);
        totalUsers.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    genderFilter,
    isLineLinkedFilter,
    lineIdFilter,
    phoneFilter,
  };
}
