<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-toggle.collapse-1
          variant="primary"
        >
          篩選會員
        </b-button>
        <b-collapse id="collapse-1" class="mt-2">
          <users-list-filters
            :is-line-linked-filter.sync="isLineLinkedFilter"
            :gender-filter.sync="genderFilter"
            :isLineLinkedOptions="isLineLinkedOptions"
            :genderOptions="genderOptions"
            :lineIdFilter.sync="lineIdFilter"
            :phoneFilter.sync="phoneFilter"
          />
        </b-collapse>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>顯示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>筆資料</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜尋姓名"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="沒有資料"
        :sort-desc.sync="isSortDirDesc"
        selectable
        @row-selected="onRowSelected"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner variant="primary" class="align-middle"></b-spinner>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="36"
                :src="data.item.avatarUrl"
                :text="data.item.name"
                variant=""
              />
            </template>
            <div class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </div>
            <small class="text-muted">{{ data.item.name }}</small>
          </b-media>
        </template>

        <!-- Column: Tags -->
        <template #cell(tags)="data">
          <b-badge
            v-for="item in data.item.tags"
            :key="item.key"
            pill
            variant="primary"
            class="mr-1"
          >
            {{ item.value }}
          </b-badge>
        </template>

        <template #cell(birth)="data">
          {{ getDateDisplay(data.item.birth) }}
        </template>

        <template #cell(isLinked)="data">
          <feather-icon
            v-if="data.item.isLinked === 1"
            icon="LinkIcon"
            size="14"
          />
        </template>

        <template #cell(joinTime)="data">
          {{ getDateTimeDisplay(data.item.joinTime) }}
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCollapse,
  VBToggle,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import { getDateTimeDisplay, getDateDisplay } from "@core/utils/utils";
import { useRouter } from "@core/utils/utils";

export default {
  components: {
    UsersListFilters,

    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCollapse,
    BSpinner,

    vSelect,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  setup() {
    const { router } = useRouter();
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const onRowSelected = (item) => {
      router.push({ path: `/apps/chat/${item[0].id}` });
    };

    const genderOptions = [
      { label: "女", value: "0" },
      { label: "男", value: "1" },
      { label: "不明", value: "2" },
    ];

    const isLineLinkedOptions = [
      { label: "未綁定", value: "0" },
      { label: "已綁定", value: "1" },
    ];

    // const isBusy = false;

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      genderFilter,
      isLineLinkedFilter,
      lineIdFilter,
      phoneFilter,
    } = useUsersList();

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      onRowSelected,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      genderOptions,
      isLineLinkedOptions,

      // Extra Filters
      genderFilter,
      isLineLinkedFilter,
      lineIdFilter,
      phoneFilter,

      // Utilis
      getDateTimeDisplay,
      getDateDisplay,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
